/* eslint-disable @next/next/no-img-element */
import * as React from 'react'
import { FC, useState } from 'react'
import styles from 'pagestyles/login.module.scss'
import { ImgBackground } from 'components/basic/Img'
import { inject, observer } from 'mobx-react'
import { PropsWithRootStore } from 'constants/types'
import { Header } from 'sections/Header'
import { Button } from 'components/WebEv/Buttons'
import { useSearchParams } from 'next/navigation'
import { ULTRA_MEMBER_UPGRADE_DISCOUNT } from 'stripe_lib/discounts'
import { PriceManager } from 'prices'
import { RegionShort } from 'utils/internationalization'
import { generateRandomState, getBaseURL, getEnv } from 'components/_utils/urlUtils'

type FormType = 'login' | 'forgot-password'

interface FormProps {
	setFormType: (formType: FormType) => void
	discount?: string
	region: RegionShort
}

const clientSecret = '57d86e7c6a887f9bb88619e15e1662613d13ac1a3666fe1e16849765ca5e2613'
const client = '48f966b6910a4b6f80d992a0fa022465'

const getRedirectURI = (region: RegionShort) => {
	const baseURL = getBaseURL()
	const destination = 'pod4-member'

	let uri = `${baseURL}/${destination}`
	if (region !== '') {
		uri = `${baseURL}/${region}/${destination}`
	}

	return encodeURIComponent(uri)
}

const ForgottenPasswordForm = (props: FormProps) => {
	const [successMessage, setSuccessMessage] = useState('')
	const [error, setError] = useState('')

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setSuccessMessage('')
		setError('')
		const email = (event.target as HTMLFormElement).email.value

		fetch('/api/forgot-password', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email }),
		})
			.then(async (response) => {
				const result = await response.json()
				if (response.status === 200 && result.success) {
					setSuccessMessage('Check your email')
				} else {
					setError('An error occurred. Please try again.')
				}
			})
			.catch((error) => {
				console.error('Forgot password error', error)
				setError('An error occurred. Please try again.')
			})
	}

	if (successMessage) {
		return (
			<div className={styles.login_modal}>
				<h1>{successMessage}</h1>
				<div className={styles.message}>Password reset instructions have been sent to your email.</div>
				<Button.Empty
					type="button"
					className={styles.cta_button}
					id="forgot-password"
					onClick={() => props.setFormType('login')}
				>
					Go back
				</Button.Empty>
			</div>
		)
	}

	return (
		<form
			action=""
			method={'POST'}
			className={styles.login_modal}
			onSubmit={handleSubmit}
		>
			<h1>Set a new password</h1>
			<h2>Enter the email associated with your Eight Sleep account and we’ll send you instructions to reset your password.</h2>
			{error && <div className={styles.error}>{error}</div>}
			<input
				type="email"
				placeholder="Email"
				name="email"
				id="email"
			/>
			<Button.Secondary
				className={styles.cta_button}
				id={'forgot-password-button'}
				type="submit"
			>
				Send
			</Button.Secondary>
			<Button.Empty
				type="button"
				className={styles.empty_button}
				id="forgot-password"
				onClick={() => props.setFormType('login')}
			>
				Return to login
			</Button.Empty>
		</form>
	)
}

const LoginForm = (props: FormProps) => {
	const queryParams = useSearchParams()

	const randomState = generateRandomState()
	if (typeof window !== 'undefined') {
		localStorage.setItem('loginState', randomState)
	}

	return (
		<form
			action={`https://auth-api.8slp.net/v1/ui/authorize?response_type=code&redirect_uri=${getRedirectURI(props.region)}&client_id=${client}&region=${
				props.region || 'us'
			}&env=${getEnv()}&state=${randomState}&client_secret=${clientSecret}`}
			method={'POST'}
			className={styles.login_modal}
		>
			<h1>Sign in to unlock your exclusive member offer</h1>
			<h2>Sign in with your Eight Sleep account to save up to {props.discount} off when you upgrade. Your new Pod will be added to your existing account.</h2>
			{queryParams.get('auth_error') && <div className={styles.error}>Invalid email or password.</div>}
			<input
				type="email"
				placeholder="Email"
				name="email"
				id="email"
			/>
			<input
				type="password"
				placeholder="Password"
				name="pass"
				id="pass"
			/>
			<input
				type="hidden"
				id="clientSecret"
				name="clientSecret"
				value={clientSecret}
			/>
			<Button.Secondary
				className={styles.cta_button}
				id={'login-button'}
				type="submit"
			>
				Sign in
			</Button.Secondary>
			<Button.Empty
				type="button"
				className={styles.empty_button}
				id="forgot-password"
				onClick={() => props.setFormType('forgot-password')}
			>
				I forgot my password
			</Button.Empty>
		</form>
	)
}

const LoginPage: FC<PropsWithRootStore> = (props) => {
	const [formType, setFormType] = useState<FormType>('login')
	const currency = props.rootStore.priceStore.currency
	const maxMemberDiscount = PriceManager.formatPriceToCurrencyNoDecimal(ULTRA_MEMBER_UPGRADE_DISCOUNT, currency)

	//const client = 'c716ea620db54529b1e2d3b58ad994e9'
	//const redirectUri = 'https%3A%2F%2Flocalhost%3A3000%2Fpod4-member'

	const renderForm =
		formType === 'login' ? (
			<LoginForm
				discount={maxMemberDiscount}
				setFormType={setFormType}
				region={props.rootStore.settingsStore.currentRegion}
			/>
		) : formType === 'forgot-password' ? (
			<ForgottenPasswordForm
				setFormType={setFormType}
				region={props.rootStore.settingsStore.currentRegion}
			/>
		) : null

	return (
		<>
			<Header />
			<section className={styles.hero}>
				<ImgBackground
					src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/login-background-desktop-2024.jpg'}
					alt={''}
					sources={[{ mediaQuery: '(max-width: 1023px)', src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/login-background-desktop-2024.jpg' }]}
					lazy={false}
					dprHeight={1000}
				>
					{renderForm}
				</ImgBackground>
			</section>
		</>
	)
}

export default inject('rootStore')(observer(LoginPage))
